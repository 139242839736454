import React from "react";

import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { AnimLayerIgamingCards } from "~animations/__layers__/mock_ui_components/AnimLayerIgamingCards";
import { AnimLayerIgamingIcons } from "~animations/__layers__/mock_ui_components/AnimLayerIgamingIcons";
import { AnimLayerUiAccountInfoCard } from "~animations/__layers__/mock_ui_components/AnimLayerUiAccountInfoCard";
import { AnimLayerUiBottomNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiBottomNav";
import { AnimLayerUiFirstDepositCard } from "~animations/__layers__/mock_ui_components/AnimLayerUiFirstDepositCard";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton/types";
import type { AnimLayerIgamingCardsProps } from "~animations/__layers__/mock_ui_components/AnimLayerIgamingCards";
import type { AnimLayerIgamingIconsProps } from "~animations/__layers__/mock_ui_components/AnimLayerIgamingIcons";
import type { AnimLayerUiAccountInfoCardProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiAccountInfoCard";
import type { AnimLayerUiFirstDepositCardProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiFirstDepositCard";
import type { AnimatedHeroIllustration } from "~animations/products_addons/types";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimIndustryIGamingProps
  extends StoryblokBlok,
    AnimatedHeroIllustration {
  animLayerUiAccountInfoCard: Array<
    StoryblokBlok & AnimLayerUiAccountInfoCardProps
  >;
  animLayerUiFirstDepositCard?: Array<
    StoryblokBlok & AnimLayerUiFirstDepositCardProps
  >;
  animLayerIgamingIcons: Array<StoryblokBlok & AnimLayerIgamingIconsProps>;
  animLayerIgamingCards: Array<StoryblokBlok & AnimLayerIgamingCardsProps>;
  animLayerFakeButton?: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  brandLogo?: string;
}

export function AnimIndustryIGaming({
  animLayerIgamingCards: animLayerIgamingCardsBlokArray,
  animLayerIgamingIcons: animLayerIgamingIconsBlokArray,
  animLayerUiAccountInfoCard: animLayerUiAccountInfoCardBlokArray,
  animLayerUiFirstDepositCard: animLayerUiFirstDepositCardBlokArray,
  brandLogo,
  animLayerFakeButton,
  ...rest
}: AnimIndustryIGamingProps) {
  const [animLayerUiAccountInfoCard] =
    animLayerUiAccountInfoCardBlokArray || [];

  const [animLayerUiFirstDepositCard] =
    animLayerUiFirstDepositCardBlokArray || [];

  const [animLayerIgamingIcons] = animLayerIgamingIconsBlokArray || [];
  const [animLayerIgamingCards] = animLayerIgamingCardsBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      <AnimLayerUiTopNav
        brandLogo={brandLogo}
        brandLogoPosition="left"
        paddingTop="phoneSpacing4"
        animLayerFakeButton={animLayerFakeButton}
      />

      {animLayerUiAccountInfoCard && (
        <ShowChildren when={animLayerUiAccountInfoCard}>
          <AnimLayerUiAccountInfoCard
            marginX="spacing2"
            {...animLayerUiAccountInfoCard}
          />
        </ShowChildren>
      )}

      <ShowChildren when={animLayerUiFirstDepositCard}>
        <AnimLayerUiFirstDepositCard
          marginX="spacing2"
          {...animLayerUiFirstDepositCard}
        />
      </ShowChildren>

      {animLayerIgamingIcons && (
        <AnimLayerIgamingIcons marginX="spacing2" {...animLayerIgamingIcons} />
      )}

      {animLayerIgamingCards && (
        <AnimLayerIgamingCards marginX="spacing2" {...animLayerIgamingCards} />
      )}

      <AnimLayerUiBottomNav />
    </AnimatedMockUiWrapper>
  );
}
